// 封装请求库
import router from "@/router";
import store from "@/store";
import Axios from "axios";
// 按需引入element的消息弹窗
import { Message } from "element-ui";

// 通过create创建axios实例
const request = Axios.create({
  // 配置公共请求地址
  baseURL: "https://yyy.hzgdwl.com/api/",
  // baseURL: "https://www.yyymall.com/api/",
  // 请求超时时间
  timeout: 5000,
});

// 定义请求拦截器
request.interceptors.request.use(
  (config) => {
    // 给每个请求携带 userTempId  通过uuid的v4加密 生成ID
    // config.headers.userTempId = getV4()
    // 给每个请求携带 token
    if (store.getters.token) {
      config.headers.token = store.getters.token;
    }
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

// 定义响应拦截器
request.interceptors.response.use(
  // 响应成功
  async (res) => {
    // 取出响应结果
    const data = res.data;
    // 判断响应码
    if (data.code !== 200) {
      if (data.code == 201) {
        Message({
          type: "success",
          message: "订单提交成功！",
          customClass: "messageIndex",
        });
        router.replace(
          {
            path: "/payok",
          },
          () => { },
          () => { }
        );
        return;
      }

      Message(data.msg || "响应错误");

      return Promise.reject(new Error(data.msg));
    }

    return data.data || data;
  },
  // 响应失败
  (err) => {
    console.log(err, '定义响应拦截器');

    let response = err.response;
    if (response) {
      const status = response.status;
      if (status == 401) {
        // 回到首页
        router.replace(
          {
            path: "/home",
          },
          () => { },
          () => { }
        );
        localStorage.removeItem('loginInfo')
        // 刷新页面
        window.location.reload();
        Message("请登录！");
      }

      if (status == 405 || status == 406) {
        router.replace(
          {
            path: "/infoRenzheng",
          },
          () => { },
          () => { }
        );
      } else if (status == 42002) {
        router.replace(
          {
            path: "/order",
            query: { isShow: 21 }
          },
          () => { },
          () => { }
        );
      }
    }


    let arr = [405, 406, 40001, 42001]
    if (arr.indexOf(status) != -1) {
      let loginInfo = JSON.parse(localStorage.getItem("loginInfo"))
      loginInfo.company_id = '';
      localStorage.setItem('loginInfo', JSON.stringify(loginInfo))
    }

    // Message(err)
    new Promise(new Error(err));
  }
);

// 导入创建的axios实例
export default ({ method, url, data }) => {
  return request({
    method,
    url,
    // 如果方法为get则使用 params传值 否则使用data传值
    [method.toLowerCase() === "get" ? "params" : "data"]: data,
  });
};
