// 导入API接口函数
import { getAddressPageList, getGoodsDetail } from '@/api';

// 首页模块
export default {
  namespaced: true, // 命名空间
  state: {
    // 地址列表
    addressList: [],

    // 商品详情信息
    goodsInfo: {},
    // 当前显示的图片
    activeImg: ''
  },

  mutations: {
    // 修改地址列表
    SET_ADDRESS_LIST(state, address) {
      state.goodsInfo = address
    },

    SET_GOODS_INFO(state, payload) {
      state.goodsInfo = payload
    },

    SET_IMG(state, img) {
      state.activeImg = img
    }
  },

  actions: {
    // 获取地址列表
    async getAddressPageList({ commit }, query) {
      const res = await getAddressPageList(query);
      console.log(res, '获取地址列表');
      commit('SET_ADDRESS_LIST', res)
    },

    async getGoodsDetail({ commit }, skuId) {
      const data = await getGoodsDetail(skuId)
      console.log(data, '获取商品详情11111');

      commit('SET_GOODS_INFO', data)
      commit('SET_IMG', data.images[0])
      // commit('SET_IMG', data.images[0].image)
    }
  },

  getters: {

  }
}